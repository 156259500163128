<template>
  <div>
    <Form ref="form" :model="form" label-width="100px">
      <FormItem label="顶部：">
        <Row>
          <Col class="welfare-subtitle">大图：</Col>
          <Col :span="10">
            <UploadImgAndVideo></UploadImgAndVideo>
          </Col>
        </Row>
        <Row class="mt-10">
          <Col class="welfare-subtitle">励志标语：</Col>
          <Col :span="10">
            <Input placeholder="请输入励志标语" v-model="form.biaoyu1" />
          </Col>
        </Row>
        <Row>
          <Col class="welfare-subtitle color-white">111</Col>
          <Col :span="10">
            <Input class="mt-10" placeholder="请输入人名" />
          </Col>
        </Row>
      </FormItem>
      <FormItem label="媒体报道：">
        <Row>
          <Col class="welfare-subtitle">图一：</Col>
          <Col :span="10">
            <UploadImgAndVideo></UploadImgAndVideo>
          </Col>
        </Row>
        <Row>
          <Col class="welfare-subtitle">标题一：</Col>
          <Col :span="10">
            <Input placeholder="请输入标题一" v-model="form.biaoyu1" />
          </Col>
        </Row>

        <Row class="mt-10">
          <Col class="welfare-subtitle">图二：</Col>
          <Col :span="10">
            <UploadImgAndVideo></UploadImgAndVideo>
          </Col>
        </Row>
        <Row>
          <Col class="welfare-subtitle">标题二：</Col>
          <Col :span="10">
            <Input placeholder="请输入标题二" v-model="form.biaoyu1" />
          </Col>
        </Row>

        <Row class="mt-10">
          <Col class="welfare-subtitle">图三：</Col>
          <Col :span="10">
            <UploadImgAndVideo></UploadImgAndVideo>
          </Col>
        </Row>
        <Row>
          <Col class="welfare-subtitle">标题三：</Col>
          <Col :span="10">
            <Input placeholder="请输入标题三" v-model="form.biaoyu1" />
          </Col>
        </Row>
        <Row class="mt-10">
          <Col class="welfare-subtitle">励志标语：</Col>
          <Col :span="10">
            <Input placeholder="请输入励志标语" v-model="form.biaoyu1" />
          </Col>
        </Row>
        <Row>
          <Col class="welfare-subtitle color-white">111</Col>
          <Col :span="10">
            <Input class="mt-10" placeholder="请输入人名" />
          </Col>
        </Row>
      </FormItem>
      <FormItem label="社会公益：">
        <Col :span="10">
          <UploadImgAndVideo></UploadImgAndVideo>
        </Col>
      </FormItem>
      <FormItem label="">
        <Button class="submit-btn" size="medium" type="primary">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>
  <script>
import { Form, FormItem, Input, Row, Col, Button } from "element-ui";
import UploadImgAndVideo from "../components/UploadImgAndVideo.vue";
export default {
  components: {
    Form,
    FormItem,
    UploadImgAndVideo,
    Input,
    Row,
    Col,
    Button,
  },
  data() {
    return {
      form: {
        biaoyu1: "",
      },
      video1:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/videos/shouye123.mp4",
      video2:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/videos/pinpaizeren.mp4",
    };
  },
  methods: {
    delVideo(count) {
      if (count == 1) {
        this.video1 = "";
      }
      if (count == 2) {
        this.video2 = "";
      }
    },
  },
};
</script>
  <style lang="scss">
.video-content {
  position: relative;
  width: 300px;
  height: 200px;
  border: 1px solid #efefef;
  margin-top: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  .video-content-desc {
    color: #efefef;
  }
  .qs-video {
    width: 100%;
    max-height: 200px;
  }
  .el-icon-circle-close {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 20px;
    color: red;
    z-index: 100;
    cursor: pointer;
  }
}
.mt-10 {
  margin-top: 10px;
}
.welfare-subtitle {
  width: 92px;
  &.color-white {
    color: white;
  }
}
.submit-btn,
.submit-btn:hover,
.submit-btn:focus {
  width: 80px;
  background-color: rgb(157, 30, 30);
  border-color: rgb(157, 30, 30);
}
</style>
  